import React, { useState } from 'react';
import { Img } from 'react-image';
import loading from '../assets/images/gifs/loading.gif';
import { FaPlay } from 'react-icons/fa';

import { Carousel } from 'react-bootstrap';

import asflSvbl from '../assets/images/references/asfl_svbl.png';
import auer from '../assets/images/references/auer.png';
import bkg from '../assets/images/references/bkg.png';
import dentaleBau from '../assets/images/references/dentale_bau.png';
import franziskaWeibel from '../assets/images/references/franziska_weibel.png';
import fujifilm from '../assets/images/references/fujifilm.png';
import gemeindeBonstetten from '../assets/images/references/gemeinde_bonstetten.png';
import gynpraxis from '../assets/images/references/gynpraxis.png';
import infosys from '../assets/images/references/infosys.png';
import insaTech from '../assets/images/references/insa_tech.png';
import lenzTreuhand from '../assets/images/references/lenz_treuhand.png';
import mrGreen from '../assets/images/references/mr_green.png';
import mueller from '../assets/images/references/mueller.png';
import solera from '../assets/images/references/solera.png';
import spf24 from '../assets/images/references/spf24.png';
import spitzenhaus from '../assets/images/references/spitzenhaus.png';
import stadtspitalWeid from '../assets/images/references/stadtspital_waid.png';
import suzette from '../assets/images/references/suzette.png';
import tesla from '../assets/images/references/tesla.png';
import thalesAlenia from '../assets/images/references/thales_alenia.png';
import tier from '../assets/images/references/tier.png';
import intershop from '../assets/images/references/intershop.png';
import meierKopp from '../assets/images/references/meier_kopp.png';
import miele from '../assets/images/references/miele.png';
import preisigElektro from '../assets/images/references/preisig_elektro.png';
import wirz from '../assets/images/references/wirz.png';
import turbinenbraeu from '../assets/images/references/turbinenbraeu.png';
import beutterKuechenAg from '../assets/images/references/beutter_kuechen_ag.png';
import elza from '../assets/images/references/elza.png';
import mitoAg from '../assets/images/references/mito.png';
import theSkin from '../assets/images/references/the_skin.png';
import threeK from '../assets/images/references/3k.png';
import garageBlaas from '../assets/images/references/garage_blaas.png';


interface IReferenceSlideshowProps {
    number: number
}

interface IReference {
    img: string,
    alt: string,
    url: string
}

function ReferenceSlideshow({ number }: IReferenceSlideshowProps) {
    const [references, setReferences] = useState<Array<IReference>>([
        {
            img: intershop,
            alt: 'Intershop',
            url: 'https://intershop.ch/',
        },
        {
            img: meierKopp,
            alt: 'Meier Kopp',
            url: 'https://www.meier-kopp.ch/',
        },
        {
            img: miele,
            alt: 'Miele',
            url: 'https://www.miele.ch/',
        },
        {
            img: preisigElektro,
            alt: 'Preisig Elektro',
            url: 'https://www.preisigelektro.ch/',
        },
        {
            img: wirz,
            alt: 'Wirz',
            url: 'https://www.wirz.ch/',
        },
        {
            img: thalesAlenia,
            alt: 'Thales Alenia',
            url: 'https://www.thalesgroup.com/en',
        },
        {
            img: solera,
            alt: 'Solera',
            url: 'https://solera-spain.ch/',
        },
        {
            img: bkg,
            alt: 'BKG',
            url: 'https://www.bkg.ch/',
        },
        {
            img: dentaleBau,
            alt: 'Dentale Bau',
            url: 'https://dentale-gipser.ch/',
        },
        {
            img: infosys,
            alt: 'Infosys',
            url: 'https://www.infosys.com/',
        },
        {
            img: turbinenbraeu,
            alt: 'Turbinenbräu',
            url: 'http://www.turbinenbraeu.ch/',
        },
        {
            img: lenzTreuhand,
            alt: 'Lenz Treuhand',
            url: 'https://www.lenz-treuhand.ch/',
        },
        {
            img: insaTech,
            alt: 'Insa Tech',
            url: 'https://insatech-ag.ch/',
        },
        {
            img: franziskaWeibel,
            alt: 'Franziska Weibel',
            url: 'https://www.coiffurefranziskaweibel.ch/',
        },
        {
            img: tesla,
            alt: 'Tesla',
            url: 'https://www.tesla.com/de_ch',
        },
        {
            img: asflSvbl,
            alt: 'ASFL SVBL',
            url: 'https://www.svbl.ch/',
        },
        {
            img: mueller,
            alt: 'Müller',
            url: 'https://kmuellerag.ch/',
        },
        {
            img: stadtspitalWeid,
            alt: 'Stadtspital Waid',
            url: 'https://www.stadt-zuerich.ch/waid/de/index.html',
        },
        {
            img: mrGreen,
            alt: 'MrGreen',
            url: 'https://mr-green.ch/',
        },
        {
            img: suzette,
            alt: 'Suzette',
            url: 'https://s-p-tec.ch/',
        },
        {
            img: tier,
            alt: 'TIER',
            url: 'https://www.tier.app/de/',
        },
        {
            img: spitzenhaus,
            alt: 'Spitzenhaus',
            url: 'https://www.spitzenhaus.com/de',
        },
        {
            img: gynpraxis,
            alt: 'Gynpraxis',
            url: 'http://www.gynpraxis.ch/',
        },
        {
            img: auer,
            alt: 'Auer',
            url: 'https://www.auer-augenoptik.ch/',
        },
        {
            img: spf24,
            alt: 'SPF24',
            url: 'https://www.spf24.ch/',
        },
        {
            img: gemeindeBonstetten,
            alt: 'Gemeinde Bonstetten',
            url: 'http://www.bonstetten.ch/de/',
        },
        {
            img: fujifilm,
            alt: 'Fujifilm',
            url: 'https://fuji.ch/',
        },
        {
            img: beutterKuechenAg,
            alt: 'Beutter Küchen AG',
            url: 'https://www.beutterkuechen.ch/',
        },
        {
            img: elza,
            alt: 'ELZA',
            url: 'https://www.elza-institute.com/de-ch/',
        },
        {
            img: mitoAg,
            alt: 'Mito AG',
            url: 'https://www.mito-ag.ch/',
        },
        {
            img: theSkin,
            alt: 'the Skin',
            url: 'https://www.theskin.ch/',
        },
        {
            img: threeK,
            alt: '3K Klima Kälte Kopp',
            url: 'https://3-k.ch/de/',
        },
        {
            img: garageBlaas,
            alt: 'Garage Blaas',
            url: 'https://garage-blaas.ch/',
        },
    ]);

    /** 
    * Takes an array with references and splits it into multiple arrays of size number. 
    * Returns a new array containing the splitted references.
    */
    const splitReferencesIntoArrays = (references: Array<IReference>, number: number): Array<Array<IReference>> => {
        const splittedReferences = Array<Array<IReference>>();
        let temp = Array<IReference>();
        references.forEach((reference, index) => {
            if (index === 0 || index % number !== 0) {
                temp[index] = reference;
            } else {
                splittedReferences.push(temp);
                temp = Array<IReference>();
                temp[index] = reference;
            }

            if (index === references.length - 1) {
                splittedReferences.push(temp);
            }
        })
        return splittedReferences;
    }
    const [splittedReferences, setSplittedReferences] = useState(splitReferencesIntoArrays(references, number));

    return (
        <Carousel className="reference-slideshow-container" nextIcon={<FaPlay />} prevIcon={<FaPlay />}>
            {
                splittedReferences.map((element, index) => {
                    return (
                        <Carousel.Item key={`carousel-item-${index}`}>
                            {
                                element.map((reference, index) => {
                                    return (
                                        <div className="reference" key={`reference-${index}`}>
                                            <a href={reference.url} target="_blank" rel="noreferrer">
                                                <Img src={reference.img} alt={reference.alt} loader={<img src={loading} alt="Loading" />} />
                                            </a>
                                        </div>
                                    );
                                })
                            }
                        </Carousel.Item>
                    );
                })
            }
        </Carousel>
    );
}

export default ReferenceSlideshow;