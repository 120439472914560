import React from 'react';
import { Modal } from 'react-bootstrap';

interface IStandwerbeanlagenModalProps {
    show: boolean,
    onHide(): void,
}

function StandwerbeanlagenModal({ show, onHide }: IStandwerbeanlagenModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="standwerbeanlagen-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Standwerbeanlagen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Ihre Kundschaft läuft sprichwörtlich an Ihnen vorbei?
                    Mit unseren Werbepylonen gehen Sie auf Blick- und Kundenfang!
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default StandwerbeanlagenModal;