import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

interface ILoadingModalProps {
    show: boolean,
    onHide(): void,
}

function LoadingModal({ show, onHide }: ILoadingModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="loading-modal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className="please-wait-container">
                        <Spinner animation="border" variant="success" /><span className="please-wait-text">Bitte warten...</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
        </Modal>
    );
}

export default LoadingModal;