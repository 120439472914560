import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import MenuModal from '../modals/MenuModal';

function Menu() {
    const [showMenuModal, setShowMenuModal] = useState(false);

    return (
        <>
            <div className="menu-btn">
                <FaBars onClick={() => setShowMenuModal(true)} />
            </div>
            <MenuModal
                show={showMenuModal}
                onHide={() => setShowMenuModal(false)}
            />
        </>
    );
}

export default Menu;