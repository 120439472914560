import React from 'react';

function Intro() {
    return (
        <section className="section-intro">
            <article className="intro-werbetechnik">
                <h2>Werbetechnik</h2>
                <p>Wir fertigen für Sie alle Arten von Reklamen. Sie möchten mit Ihrer Botschaft das Publikum überzeugen, möchten Orientierung mit Beschriftung erreichen, möchten den besten Auftritt auf der Strasse und ebenfalls nicht im Dunkeln übersehen werden? Bei uns erfahren Sie eine fachliche Beratung im Bereich Werbetechnik. Unsere Passion und stets qualitätsorientiertes Arbeiten zeichnet uns als Unternehmen aus. Mit langjähriger Erfahrung und mit viel Liebe zum Detail wird Ihre Idee zum persönlichen Eye-Catcher.</p>
            </article>
            <article className="intro-signaletik">
                <h2>Signaletik</h2>
                <p className="greenyellow space"><strong>Leit- und Orientierungssysteme</strong></p>
                <p className="space">Signaletik klingt etwas sperrig, doch spätestens wenn man unter Zeitdruck auf dem Weg zu seinem Flug ist oder gar im Falle eines Feueralarms ein Gebäude schnellstmöglich verlassen muss, wird eben diese Signaletik unverzichtbar. Dann nämlich weist ein gutes Leit- und Orientierungssystem den richtigen Weg entlang zum Ziel – auf dem Flughafen, Bahnhof, im Shoppingcenter, auf dem Messegelände oder auch in einem Unternehmen bzw. öffentlichen Gebäude.</p>
                <p className="space">Gerne beraten wir Sie persönlich in unserem Atelier an der Silbernstrasse 18 in 8953 Dietikon.</p>
                <p>Wir freuen uns über weitere kreative Aufträge!</p>
            </article>
        </section>
    );
}

export default Intro;