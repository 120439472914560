import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import ReCaptcha from '../instances/ReCaptcha';

interface IContactFormReCaptchaModalProps {
    show: boolean,
    onHide(): void,
    setVerified(): void,
    verified: boolean,
}

function ContactFormReCaptchaModal({ show, onHide, setVerified, verified }: IContactFormReCaptchaModalProps) {
    const recaptcha = new ReCaptcha('g-recaptcha-contact-form', 'light', () => setVerified());

    useEffect(() => {
        if (show) {
            recaptcha.load();
        }
        return () => {
            recaptcha.cleanup();
        }
    }, [show])

    const handleSend = () => {
        recaptcha.reset();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="contact-form-recaptcha-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    reCAPTCHA
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="g-recaptcha-contact-form"></div>
            </Modal.Body>
            <Modal.Footer>
                <input form="contact-form" type="submit" onClick={handleSend} value="Senden" disabled={!verified} />
            </Modal.Footer>
        </Modal>
    );
}

export default ContactFormReCaptchaModal;