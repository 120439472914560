import React from 'react';
import ReferenceSlideshow from '../components/ReferenceSlideshow';

interface IReferenceProps {
    width: number,
}

function Reference({ width }: IReferenceProps) {
    let referenceSlideshow = null;
    if (width < 824) {
        referenceSlideshow = <ReferenceSlideshow number={1} />;
    } else if (width >= 824 && width < 1600) {
        referenceSlideshow = <ReferenceSlideshow number={2} />;
    } else if (width >= 1600 && width < 1900) {
        referenceSlideshow = <ReferenceSlideshow number={3} />;
    } else if (width >= 1900 && width < 2200) {
        referenceSlideshow = <ReferenceSlideshow number={4} />;
    } else {
        referenceSlideshow = <ReferenceSlideshow number={5} />;
    }

    return (
        <section id="referenz" className="section-reference">
            <h2>Referenz</h2>
            {
                referenceSlideshow
            }
        </section>
    );
}

export default Reference;