import React, { useState } from 'react';
import { Img } from 'react-image';
import ivanCastro from '../assets/images/team/ivancastro.jpg';
import andreaCastro from '../assets/images/team/andreacastro.jpg';
import bongo from '../assets/images/team/bongo.jpg';
import loading from '../assets/images/gifs/loading.gif';

interface IEmployee {
    img: string,
    role: string,
    fullname: string,
    mail: string,
}

function Company() {
    const [employees, setEmployees] = useState<Array<IEmployee>>([
        {
            img: ivanCastro,
            role: 'Inhaber',
            fullname: 'Ivan Castro',
            mail: 'ivan@castrovisual.ch'
        },
        {
            img: andreaCastro,
            role: 'Leitung Administration',
            fullname: 'Andrea Castro',
            mail: 'andrea@castrovisual.ch'
        },
        {
            img: bongo,
            role: 'Leitung Betriebsklima',
            fullname: 'Bongo',
            mail: 'info@castrovisual.ch'
        },
    ]);

    return (
        <section id="unternehmen" className="section-company">
            <article className="intro-company">
                <h2>Unternehmen</h2>
                <p>Castro Visual ist seit 2015 ein Werbetechnik Unternehmen, welches sich auf visuelle Kommunikation mit Anspruch spezialisiert hat. Dank unserer Erfahrung und Leidenschaft für Werbetechnik überzeugen wir unsere Kunden mit Kreativität und Qualität. Mit den neusten Folienplottern und Latex-Grossformatdruckern holen wir Visionen vom Bildschirm in die Realität.</p>
            </article>
            <div className="employee-container">
                {
                    employees.map((employee, index) => {
                        return (
                            <div className="employee" key={`employee-${index}`}>
                                <Img src={employee.img} alt={employee.fullname} loader={<img src={loading} alt="Loading"/>} />
                                <div className="employee-info">
                                    <p>{employee.role}<br />{employee.fullname}</p>
                                    <a href={`mailto:${employee.mail}`}>{employee.mail}</a>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
}

export default Company;