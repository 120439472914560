import React from 'react';
import EmailJS from '../components/EmailJS';

function ContactForm() {
    return (
        <section id="kontakt" className="section-contact-form">
            <div className="intro-contact-form-container">
                <article className="intro-contact-form">
                    <h2>Kontaktformular</h2>
                    <p>Starten Sie mit uns heute noch Ihr Beschriftungsprojekt. Gerne laden wir Sie zu einem Kaffee ein um alle Ihre Fragen und Details zu klären.</p>
                </article>
            </div>
            <EmailJS />
        </section>
    );
}

export default ContactForm;