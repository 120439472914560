import emailjs from 'emailjs-com';
import React, { useState, FormEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Swal from 'sweetalert2';
import ContactFormReCaptchaModal from '../modals/ContactFormReCaptchaModal';
import LoadingModal from '../modals/LoadingModal';

function EmailJS() {
    const [showReCaptchaModal, setShowReCaptchaModal] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [verified, setVerified] = useState(false);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (showReCaptchaModal) {
            setShowReCaptchaModal(false);
            setShowLoadingModal(true);

            emailjs.sendForm('service_vmdxe0v', 'template_kqm9tt8', event.currentTarget, 'user_uojrtTsqIHKRtVSwO0RQ6')
                .then((result) => {
                    setShowLoadingModal(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Nachricht erfolgreich gesendet',
                        text: 'Ihre Nachricht wurde erfolgreich verschickt und an uns weitergeleitet.',
                        confirmButtonColor: '#23232d',
                    });
                }, (error) => {
                    setShowLoadingModal(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: 'Ihre Nachricht konnte nicht verschickt werden. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
                        confirmButtonColor: '#23232d',
                    });
                });

            event.currentTarget.reset();
        } else {
            setShowReCaptchaModal(true);
        }
    }

    const onHide = () => {
        setShowReCaptchaModal(false);
        setVerified(false);
    }

    return (
        <form id="contact-form" onSubmit={handleSubmit}>
            <div className="contact-form-container">
                <div className="inputs-container">
                    <input type="text" placeholder="Name *" name="name" required />
                    <input type="email" placeholder="E-Mail *" name="email" required />
                    <input type="tel" placeholder="Telefon" name="phone" />
                </div>
                <div className="textarea-container">
                    <TextareaAutosize maxRows={17} name="message" placeholder="Ihre Nachricht *" required />
                </div>
                <input type="submit" className="contact-form-submit-btn" value="Senden" />
            </div>
            <ContactFormReCaptchaModal show={showReCaptchaModal} onHide={() => onHide()} verified={verified} setVerified={() => setVerified(true)} />
            <LoadingModal show={showLoadingModal} onHide={() => setShowLoadingModal(false)} />
        </form>
    );
}

export default EmailJS;