import React from 'react';
import { Modal } from 'react-bootstrap';

interface IMenuModalProps {
    show: boolean,
    onHide(): void,
}

function MenuModal({ show, onHide }: IMenuModalProps) {

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="menu-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Menü
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="menu-modal-body-container">
                    <a href="#unternehmen" onClick={onHide}>Unternehmen</a>
                    <a href="#unser-service" onClick={onHide}>Unser Service</a>
                    <a href="#referenz" onClick={onHide}>Referenz</a>
                    <a href="#sponsoring" onClick={onHide}>Sponsoring</a>
                    <a href="#kontakt" onClick={onHide}>Kontakt</a>
                    <a href="#standort" onClick={onHide}>Standort</a>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default MenuModal;
