import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import GoogleMaps from '../components/GoogleMaps';

function Location() {
    return (
        <section id="standort" className="section-location">
            <h2>Standort</h2>
            <GoogleMaps />
            <div className="location-info-container">
                <div className="location-info">
                    <FaMapMarkerAlt />
                    <p className="strong">Adresse</p>
                    <a href="https://www.google.ch/maps/place/Silbernstrasse+18,+8953+Dietikon/@47.4181227,8.3935156,17z/data=!4m5!3m4!1s0x47900d09b863a157:0xfa56030daca41ff0!8m2!3d47.4182952!4d8.393876" target="_blank" rel="noreferrer">
                        <address>
                            Silbernstrasse 18<br />
                            8953 Dietikon
                        </address>
                    </a>
                </div>
                <div className="location-info">
                    <FaEnvelope />
                    <p className="strong">Telefon &amp; E-Mail</p>
                    <a href="tel:+41445588454">+41 44 558 84 54</a>
                    <a href="mailto:info@castrovisual.ch">info@castrovisual.ch</a>
                </div>
                <div className="location-info">
                    <FaClock />
                    <p><span className="strong">Öffnungszeiten</span><br />Montag bis Freitag<br />07:30 - 17:00 Uhr</p>
                </div>
            </div>
        </section>
    );
}

export default Location;