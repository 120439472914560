import React, { useState, useLayoutEffect } from 'react';
import Menu from './layout/Menu';
import Header from './sections/Header';
import Banner from './sections/Banner';
import Intro from './sections/Intro';
import Company from './sections/Company';
import Buttons from './sections/Buttons';
import Services from './sections/Services';
import ImplementationExample from './sections/ImplementationExample';
import Reference from './sections/Reference';
import Sponsoring from './sections/Sponsoring';
import Impressions from './sections/Impressions';
import ContactForm from './sections/ContactForm';
import Location from './sections/Location';
import Footer from './layout/Footer';

function App() {
  const [size, setSize] = useState([window.outerWidth, window.outerHeight]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.outerWidth, window.outerHeight]);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const [width, height] = size;

  return (
    <div className="app">
      <div className="menu">
        <Menu />
      </div>
      <div className="sections">
        <Header />
        <Banner />
        <Intro />
        <Buttons />
        <Company />
        <Services />
        <ImplementationExample />
        <Reference width={width} />
        <Impressions />
        <Sponsoring />
        <ContactForm />
        <Location />
      </div>
      <Footer />
    </div>
  );
}

export default App;
