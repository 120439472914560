import React from 'react';
import { Modal } from 'react-bootstrap';
import { GrDocumentPdf } from 'react-icons/gr';
import sonnenUndHitzschutzfolien from '../assets/files/castro_visual_sonnen_und_hitzeschutzfolien.pdf';

interface ISichtUndSonnenschutzModalProps {
    show: boolean,
    onHide(): void,
}

function SichtUndSonnenschutzModal({ show, onHide }: ISichtUndSonnenschutzModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            size="lg"
            className="sicht-und-sonnenschutz-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sicht und Sonnenschutz
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="space">
                    Unsere Sicht- und Sonnenschutzfolien erhöhen Ihre Energieffizienz,
                    schaffen ästhetischen Mehrwert oder
                    machen einfach absolut blickdicht.
                </p>
                <a href={sonnenUndHitzschutzfolien} download="Castro_Visual_Sonnen_und_Hitzeschutzfolien.pdf"><GrDocumentPdf />Castro Visual Sonnenschutz &amp; Hitzschutzfolien</a>
            </Modal.Body>
        </Modal>
    );
}

export default SichtUndSonnenschutzModal;