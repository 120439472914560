import React, { useEffect } from 'react';

function GoogleMaps() {
    const apiKey = 'AIzaSyBh6SdfdL1fsOTwaTdNm-I8bAnry_7Lc2w'

    useEffect(() => {
        const polyfill = document.createElement('script');
        polyfill.src = 'https://polyfill.io/v3/polyfill.min.js?features=default';
        document.head.appendChild(polyfill);

        const googleapis = document.createElement('script');
        googleapis.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=&v=weekly`;
        googleapis.async = true;
        googleapis.onload = initMap;
        document.body.appendChild(googleapis);

        return () => {
            document.body.removeChild(googleapis);
            document.head.removeChild(polyfill);
        }
    }, [])

    const initMap = () => {
        let map: google.maps.Map;
        const center: google.maps.LatLngLiteral = { lat: 47.41831687462002, lng: 8.393877226109046 };

        map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center,
            zoom: 13,
        });
        const marker = new google.maps.Marker({
            position: center,
            map: map,
        });
        marker.setLabel({
            color: '#252833',
            fontWeight: 'bold',
            fontSize: '18px',
            text: 'Castro Visual',
        });
    }

    return (
        <div id="map"></div>
    );
}

export default GoogleMaps;