import React, { useState } from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import { Img } from 'react-image';
import loading from '../assets/images/gifs/loading.gif';
import aussenwerbung1 from '../assets/images/impressions/aussenwerbung/1.jpg';
import aussenwerbung2 from '../assets/images/impressions/aussenwerbung/2.jpg';
import aussenwerbung3 from '../assets/images/impressions/aussenwerbung/3.jpg';
import aussenwerbung4 from '../assets/images/impressions/aussenwerbung/4.jpg';
import aussenwerbung5 from '../assets/images/impressions/aussenwerbung/5.jpg';
import aussenwerbung6 from '../assets/images/impressions/aussenwerbung/6.jpg';
import aussenwerbung7 from '../assets/images/impressions/aussenwerbung/7.jpg';
import aussenwerbung8 from '../assets/images/impressions/aussenwerbung/8.jpg';
import aussenwerbung9 from '../assets/images/impressions/aussenwerbung/9.jpg';
import aussenwerbung10 from '../assets/images/impressions/aussenwerbung/10.jpg';
import aussenwerbung11 from '../assets/images/impressions/aussenwerbung/11.jpg';
import aussenwerbung12 from '../assets/images/impressions/aussenwerbung/12.jpg';
import aussenwerbung13 from '../assets/images/impressions/aussenwerbung/13.jpg';
import aussenwerbung14 from '../assets/images/impressions/aussenwerbung/14.jpg';
import aussenwerbung15 from '../assets/images/impressions/aussenwerbung/15.jpg';
import aussenwerbung16 from '../assets/images/impressions/aussenwerbung/16.jpg';
import fahrzeugbeschriftung1 from '../assets/images/impressions/fahrzeugbeschriftung/1.jpg';
import fahrzeugbeschriftung2 from '../assets/images/impressions/fahrzeugbeschriftung/2.jpg';
import fahrzeugbeschriftung3 from '../assets/images/impressions/fahrzeugbeschriftung/3.jpg';
import fahrzeugbeschriftung4 from '../assets/images/impressions/fahrzeugbeschriftung/4.jpg';
import fahrzeugbeschriftung5 from '../assets/images/impressions/fahrzeugbeschriftung/5.jpg';
import fahrzeugbeschriftung6 from '../assets/images/impressions/fahrzeugbeschriftung/6.jpg';
import fahrzeugbeschriftung7 from '../assets/images/impressions/fahrzeugbeschriftung/7.jpg';
import fahrzeugbeschriftung8 from '../assets/images/impressions/fahrzeugbeschriftung/8.jpg';
import fahrzeugbeschriftung9 from '../assets/images/impressions/fahrzeugbeschriftung/9.jpg';
import fahrzeugbeschriftung10 from '../assets/images/impressions/fahrzeugbeschriftung/10.jpg';
import fahrzeugbeschriftung11 from '../assets/images/impressions/fahrzeugbeschriftung/11.jpg';
import fahrzeugbeschriftung12 from '../assets/images/impressions/fahrzeugbeschriftung/12.jpg';
import fahrzeugbeschriftung13 from '../assets/images/impressions/fahrzeugbeschriftung/13.jpg';
import fahrzeugbeschriftung14 from '../assets/images/impressions/fahrzeugbeschriftung/14.jpg';
import fahrzeugbeschriftung15 from '../assets/images/impressions/fahrzeugbeschriftung/15.jpg';
import fahrzeugbeschriftung16 from '../assets/images/impressions/fahrzeugbeschriftung/16.jpg';
import innenbeschriftung1 from '../assets/images/impressions/innenbeschriftung/1.jpg';
import innenbeschriftung2 from '../assets/images/impressions/innenbeschriftung/2.jpg';
import innenbeschriftung3 from '../assets/images/impressions/innenbeschriftung/3.jpg';
import innenbeschriftung4 from '../assets/images/impressions/innenbeschriftung/4.jpg';
import innenbeschriftung5 from '../assets/images/impressions/innenbeschriftung/5.jpg';
import innenbeschriftung6 from '../assets/images/impressions/innenbeschriftung/6.jpg';
import innenbeschriftung7 from '../assets/images/impressions/innenbeschriftung/7.jpg';
import innenbeschriftung8 from '../assets/images/impressions/innenbeschriftung/8.jpg';
import innenbeschriftung9 from '../assets/images/impressions/innenbeschriftung/9.jpg';
import innenbeschriftung10 from '../assets/images/impressions/innenbeschriftung/10.jpg';
import innenbeschriftung11 from '../assets/images/impressions/innenbeschriftung/11.jpg';
import lichtwerbung1 from '../assets/images/impressions/lichtwerbung/1.jpg';
import lichtwerbung2 from '../assets/images/impressions/lichtwerbung/2.jpg';
import lichtwerbung3 from '../assets/images/impressions/lichtwerbung/3.jpg';
import lichtwerbung4 from '../assets/images/impressions/lichtwerbung/4.jpg';
import lichtwerbung5 from '../assets/images/impressions/lichtwerbung/5.jpg';
import lichtwerbung6 from '../assets/images/impressions/lichtwerbung/6.jpg';
import lichtwerbung7 from '../assets/images/impressions/lichtwerbung/7.jpg';
import lichtwerbung8 from '../assets/images/impressions/lichtwerbung/8.jpg';
import lichtwerbung9 from '../assets/images/impressions/lichtwerbung/9.jpg';
import lichtwerbung10 from '../assets/images/impressions/lichtwerbung/10.jpg';
import lichtwerbung11 from '../assets/images/impressions/lichtwerbung/11.jpg';
import sichtUndSonnenschutz1 from '../assets/images/impressions/sicht_und_sonnenschutz/1.jpg';
import sichtUndSonnenschutz2 from '../assets/images/impressions/sicht_und_sonnenschutz/2.jpg';
import sichtUndSonnenschutz3 from '../assets/images/impressions/sicht_und_sonnenschutz/3.jpg';
import sichtUndSonnenschutz4 from '../assets/images/impressions/sicht_und_sonnenschutz/4.jpg';
import sichtUndSonnenschutz5 from '../assets/images/impressions/sicht_und_sonnenschutz/5.jpg';
import sichtUndSonnenschutz6 from '../assets/images/impressions/sicht_und_sonnenschutz/6.jpg';
import sichtUndSonnenschutz7 from '../assets/images/impressions/sicht_und_sonnenschutz/7.jpg';
import sichtUndSonnenschutz8 from '../assets/images/impressions/sicht_und_sonnenschutz/8.jpg';
import sichtUndSonnenschutz9 from '../assets/images/impressions/sicht_und_sonnenschutz/9.jpg';
import sichtUndSonnenschutz10 from '../assets/images/impressions/sicht_und_sonnenschutz/10.jpg';

interface IImpression {
    img: string,
    alt: string
}

function ImpressionGallery() {

    const [aussenwerbung, setAussenwerbung] = useState<Array<IImpression>>([
        {
            img: aussenwerbung14,
            alt: 'Confida',
        },
        {
            img: aussenwerbung15,
            alt: 'ELZA',
        },
        {
            img: aussenwerbung16,
            alt: 'Wirz',
        },
        {
            img: aussenwerbung13,
            alt: 'Fassadennummer',
        },
        {
            img: aussenwerbung1,
            alt: 'Andrea Wiedmer',
        },
        {
            img: aussenwerbung2,
            alt: 'ASFL SVBL',
        },
        {
            img: aussenwerbung3,
            alt: 'Auer',
        },
        {
            img: aussenwerbung4,
            alt: 'Bahnhöfli',
        },
        {
            img: aussenwerbung5,
            alt: 'Beda Steiner',
        },
        {
            img: aussenwerbung6,
            alt: 'InsaTech Tor',
        },
        {
            img: aussenwerbung7,
            alt: 'Mr.Green',
        },
        {
            img: aussenwerbung8,
            alt: 'Samsung',
        },
        {
            img: aussenwerbung9,
            alt: 'Solera',
        },
        {
            img: aussenwerbung10,
            alt: 'SPF24 Aussentafel',
        },
        {
            img: aussenwerbung11,
            alt: 'Spitzenhaus Storen',
        },
        {
            img: aussenwerbung12,
            alt: 'Tamarind Hill',
        },
    ]);
    const [fahrzeugbeschriftung, setFahrzeugbeschriftung] = useState<Array<IImpression>>([
        {
            img: fahrzeugbeschriftung15,
            alt: 'Beutter Küchen Bus',
        },
        {
            img: fahrzeugbeschriftung16,
            alt: 'SPF24 Bus',
        },
        {
            img: fahrzeugbeschriftung11,
            alt: 'Beutter Küchen AG',
        },
        {
            img: fahrzeugbeschriftung12,
            alt: 'EO Knecht',
        },
        {
            img: fahrzeugbeschriftung13,
            alt: 'Meier Kopp',
        },
        {
            img: fahrzeugbeschriftung14,
            alt: 'Preisig Elektro',
        },
        {
            img: fahrzeugbeschriftung10,
            alt: 'Miele',
        },
        {
            img: fahrzeugbeschriftung1,
            alt: 'Alpha Sprachwelt',
        },
        {
            img: fahrzeugbeschriftung2,
            alt: 'Fuji',
        },
        {
            img: fahrzeugbeschriftung3,
            alt: 'Insa Tech Auto',
        },
        {
            img: fahrzeugbeschriftung4,
            alt: 'MAX Key',
        },
        {
            img: fahrzeugbeschriftung5,
            alt: 'Meier',
        },
        {
            img: fahrzeugbeschriftung6,
            alt: 'Solera Auto',
        },
        {
            img: fahrzeugbeschriftung7,
            alt: 'Spitex',
        },
        {
            img: fahrzeugbeschriftung8,
            alt: 'Suzette',
        },
        {
            img: fahrzeugbeschriftung9,
            alt: 'Yes Direct',
        },
    ]);
    const [innenbeschriftung, setInnenbeschriftung] = useState<Array<IImpression>>([
        {
            img: innenbeschriftung11,
            alt: 'Wirz'
        },
        {
            img: innenbeschriftung10,
            alt: 'Cafeteria Waidspital'
        },
        {
            img: innenbeschriftung1,
            alt: 'SPF24'
        },
        {
            img: innenbeschriftung2,
            alt: 'Brauerei Oerlikon'
        },
        {
            img: innenbeschriftung3,
            alt: 'Cara Bird Schild'
        },
        {
            img: innenbeschriftung4,
            alt: 'ETG Rümlang'
        },
        {
            img: innenbeschriftung5,
            alt: 'Infosys'
        },
        {
            img: innenbeschriftung6,
            alt: 'SPF24'
        },
        {
            img: innenbeschriftung7,
            alt: 'SPF24 Garderobe'
        },
        {
            img: innenbeschriftung8,
            alt: 'Waidspital'
        },
        {
            img: innenbeschriftung9,
            alt: 'Brack'
        },
    ]);
    const [lichtwerbung, setLichtwerbung] = useState<Array<IImpression>>([
        {
            img: lichtwerbung11,
            alt: 'Gordion'
        },
        {
            img: lichtwerbung10,
            alt: '7030'
        },
        {
            img: lichtwerbung1,
            alt: 'ARoma'
        },
        {
            img: lichtwerbung2,
            alt: 'Coca Cola'
        },
        {
            img: lichtwerbung3,
            alt: 'Da Carlo'
        },
        {
            img: lichtwerbung4,
            alt: 'Le Raymond Bar'
        },
        {
            img: lichtwerbung5,
            alt: 'NA081'
        },
        {
            img: lichtwerbung6,
            alt: 'PizzaFitt'
        },
        {
            img: lichtwerbung7,
            alt: 'Sax'
        },
        {
            img: lichtwerbung8,
            alt: 'Tizziani'
        },
        {
            img: lichtwerbung9,
            alt: 'Yard Bird'
        },
    ]);
    const [sichtUndSonnenschutz, setSichtUndSonnenschutz] = useState<Array<IImpression>>([
        {
            img: sichtUndSonnenschutz9,
            alt: 'GreenLagoon Sichtschutz'
        },
        {
            img: sichtUndSonnenschutz10,
            alt: 'Waidspital Sonnenschutz'
        },
        {
            img: sichtUndSonnenschutz1,
            alt: 'Franco Keramik'
        },
        {
            img: sichtUndSonnenschutz2,
            alt: 'Franziska Weibel'
        },
        {
            img: sichtUndSonnenschutz3,
            alt: 'Immotions'
        },
        {
            img: sichtUndSonnenschutz4,
            alt: 'Lenz Treuhand'
        },
        {
            img: sichtUndSonnenschutz5,
            alt: 'Sichtschutz Balkon'
        },
        {
            img: sichtUndSonnenschutz6,
            alt: 'Sonnenschutz'
        },
        {
            img: sichtUndSonnenschutz7,
            alt: 'Sonnenschutz Waid Spital'
        },
        {
            img: sichtUndSonnenschutz8,
            alt: 'Sonnenschutz'
        },
    ]);
    const [impressions, setImpressions] = useState(aussenwerbung);
    const [btnAussenwerbungActive, setBtnAussenwerbungActive] = useState(true);
    const [btnInnenbeschriftungActive, setBtnInnenbeschriftungActive] = useState(false);
    const [btnLichtwerbungActive, setBtnLichtwerbungActive] = useState(false);
    const [btnSichtUndSonnenschutzActive, setBtnSichtUndSonnenschutzActive] = useState(false);
    const [btnFahrzeugbeschriftungActive, setBtnFahrzeugbeschriftungActive] = useState(false);
    const [fade, setFade] = useState(false);
    const [srlOptions, setSrlOptions] = useState({
        buttons: {
            showDownloadButton: false,
            showFullscreenButton: false,
            showThumbnailsButton: false,
            showAutoplayButton: false,
        }
    });

    const activateImpressionBtns = (btnAussenwerbung: boolean, btnInnenbeschriftung: boolean, btnLichtwerbung: boolean, btnSichtUndSonnenschutz: boolean, btnFahrzeugbeschriftung: boolean) => {
        setBtnAussenwerbungActive(btnAussenwerbung);
        setBtnInnenbeschriftungActive(btnInnenbeschriftung);
        setBtnLichtwerbungActive(btnLichtwerbung);
        setBtnSichtUndSonnenschutzActive(btnSichtUndSonnenschutz);
        setBtnFahrzeugbeschriftungActive(btnFahrzeugbeschriftung);
    }

    const onClickBtnAusssenwerbung = () => {
        activateImpressionBtns(true, false, false, false, false);
        setImpressions(aussenwerbung);
        animate();
    }

    const onClickBtnInnenbeschriftung = () => {
        activateImpressionBtns(false, true, false, false, false);
        setImpressions(innenbeschriftung);
        animate();
    }

    const onClickBtnLichtwerbung = () => {
        activateImpressionBtns(false, false, true, false, false);
        setImpressions(lichtwerbung);
        animate();
    }

    const onClickBtnSichtUndSonnenschutz = () => {
        activateImpressionBtns(false, false, false, true, false);
        setImpressions(sichtUndSonnenschutz);
        animate();
    }

    const onClickBtnFahrzeugbeschriftung = () => {
        activateImpressionBtns(false, false, false, false, true);
        setImpressions(fahrzeugbeschriftung);
        animate();
    }

    const animate = () => {
        setFade(true);
        setTimeout(() => setFade(false), 1000);
    }

    return (
        <div className="impression-gallery-container">
            <div className="impression-gallery-button-container">
                <button className={btnAussenwerbungActive ? 'active' : ''} type="button" onClick={() => onClickBtnAusssenwerbung()}>Aussenwerbung</button>
                <button className={btnInnenbeschriftungActive ? 'active' : ''} type="button" onClick={() => onClickBtnInnenbeschriftung()}>Innenbeschriftung</button>
                <button className={btnLichtwerbungActive ? 'active' : ''} type="button" onClick={() => onClickBtnLichtwerbung()}>Lichtwerbung</button>
                <button className={btnSichtUndSonnenschutzActive ? 'active' : ''} type="button" onClick={() => onClickBtnSichtUndSonnenschutz()}>Sicht- und Sonnenschutz</button>
                <button className={btnFahrzeugbeschriftungActive ? 'active' : ''} type="button" onClick={() => onClickBtnFahrzeugbeschriftung()}>Fahrzeugbeschriftung</button>
            </div>
            <SRLWrapper options={srlOptions}>
                {
                    impressions.map((element, index) => {
                        return (
                            <div className={`impression ${fade ? 'fade' : null}`} key={`impression-${index}`}>
                                <Img src={element.img} alt={element.alt} loader={<img src={loading} alt="Loading"/>}/>
                            </div>
                        );
                    })
                }
            </SRLWrapper>
        </div>
    );
}

export default ImpressionGallery;