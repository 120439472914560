import React, { useState } from 'react';
import { FaPencilRuler, FaCogs, FaTools } from 'react-icons/fa';
import ImplementationModal from '../modals/ImplementationModal';
import PlaningModal from '../modals/PlaningModal';
import ProductionModal from '../modals/ProductionModal';

function Buttons() {
    const [showPlaningModal, setShowPlaningModal] = useState(false);
    const [showProductionModal, setShowProductionModal] = useState(false);
    const [showImplementationModal, setShowImplementationModal] = useState(false);

    return (
        <section className="section-buttons">
            <div className="button-container">
                <button type="button" onClick={() => setShowPlaningModal(true)}><FaPencilRuler />Planung</button>
                <button type="button" onClick={() => setShowProductionModal(true)}><FaCogs />Produktion</button>
                <button type="button" onClick={() => setShowImplementationModal(true)}><FaTools />Umsetzung</button>
            </div>
            <PlaningModal show={showPlaningModal} onHide={() => setShowPlaningModal(false)}/>
            <ProductionModal  show={showProductionModal} onHide={() => setShowProductionModal(false)}/>
            <ImplementationModal  show={showImplementationModal} onHide={() => setShowImplementationModal(false)}/>
        </section>
    );
}

export default Buttons;