import React from 'react';
import ImpressionGallery from '../components/ImpressionGallery';

function Impressions() {
    return (
        <section className="section-impressions">
            <h2>Was wir am besten können, sehen Sie am einfachsten selbst</h2>
            <ImpressionGallery />
        </section>
    );
}

export default Impressions;