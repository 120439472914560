import React from 'react';
import { Modal } from 'react-bootstrap';

interface IFahrzeugbeschriftungModalProps {
    show: boolean,
    onHide(): void,
}

function FahrzeugbeschriftungModal({ show, onHide }: IFahrzeugbeschriftungModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="fahrzeugbeschriftung-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Fahrzeugbeschriftung
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="space">
                    Mit der richtigen Werbung, fahren Ihre Kunden auf Sie ab.
                    Zeigen Sie wer Sie sind, wo Sie sind!
                    Wir bringen Ihr Business in Fahrt!
                </p>
                <p className="space">
                    Vollverklebung: Egal ob glänzend, matt, mit Struktur oder Design.
                    Machen Sie aus Ihrem Fahrzeug ein Unikat.
                </p>
                <p>
                    Lackschutz: Die BodyFence-Folie schützt dank ihrer Spitzentechnologie
                    Ihr Fahrzeug gegen äußere Beschädigungen durch:
                    Kratzer / Steinschlag / Verschleiß / UV-Strahlung
                    Zudem wird die Reinigung der Folie durch deren
                    schmutzabweisende Oberfläche erleichtert,
                    wodurch Ihr Auto aussieht... wie am ersten Tag!
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default FahrzeugbeschriftungModal;