import React from 'react';
import { Modal } from 'react-bootstrap';

interface IDigitaldruckModalProps {
    show: boolean,
    onHide(): void,
}

function DigitaldruckModal({ show, onHide }: IDigitaldruckModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="digitaldruck-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Digitaldruck
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Drinnen oder draußen, großflächig oder filigran -
                    wir bedrucken und beschriften alles!
                    Sie haben eine Message? Wir zeigen sie der Welt!
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default DigitaldruckModal;