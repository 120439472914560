import React from 'react';
import { Modal } from 'react-bootstrap';

interface ILichtwerbungModalProps {
    show: boolean,
    onHide(): void,
}

function LichtwerbungModal({ show, onHide }: ILichtwerbungModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="lichtwerbung-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Lichtwerbung
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Alles was leuchtet bekommt mehr Aufmerksamkeit.
                    Werden Sie zum Blickmagnet!
                    Mit uns geht Ihnen und Ihren Kunden ein Licht auf!
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default LichtwerbungModal;