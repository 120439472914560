import React from 'react';
import { Modal } from 'react-bootstrap';

interface IBannerUndFahnenModalProps {
    show: boolean,
    onHide(): void,
}

function BannerUndFahnenModal({ show, onHide }: IBannerUndFahnenModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="banner-und-fahnen-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Banner und Fahnen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Zeigen Sie Ihren Kunden wer Sie sind und was Sie machen.
                    Wir halten Ihre Fahne hoch!
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default BannerUndFahnenModal;