import React, { useState } from 'react';
import { Img } from 'react-image';
import swissCentralBasketball from '../assets/images/sponsoring/central_basketball.jpg';
import unicorn from '../assets/images/sponsoring/unicorn.png';
import fcNiederweningen from '../assets/images/sponsoring/fcn.jpg';
import loading from '../assets/images/gifs/loading.gif';

interface ISponsor {
    img: string,
    alt: string,
    url: string
}

function Sponsoring() {
    const [sponsors, setSponsors] = useState<Array<ISponsor>>([
        {
            img: swissCentralBasketball,
            alt: 'Swiss Central Basketball',
            url: 'https://www.swisscentralbasketball.ch/',
        },
        {
            img: unicorn,
            alt: 'Unicorn Basketball',
            url: 'https://www.unicorn02.com/',
        },
        {
            img: fcNiederweningen,
            alt: 'FC Niederweningen',
            url: 'https://fcniederweningen.ch/',
        },
    ]);

    return (
        <section id="sponsoring" className="section-sponsoring">
            <article className="intro-sponsoring">
                <h2>Sponsoring</h2>
                <p>Wir unterstützen folgende Vereine und ihre talentierten Athleten, damit sie sich auf höchstem Niveau messen können. Die Castro Visual wünscht den Vereinen viel Glück und Erfolg in der Saison.</p>
            </article>
            <div className="sponsor-container">
                {
                    sponsors.map((sponsor, index) => {
                        return (
                            <div className="sponsor" key={`sponsor-${index}`}>
                                <a href={sponsor.url} target="_blank" rel="noreferrer">
                                    <Img src={sponsor.img} alt={sponsor.alt} loader={<img src={loading} alt="Loading"/>} />
                                </a>
                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
}

export default Sponsoring;