import React from 'react';
import { Modal } from 'react-bootstrap';

interface IInteriorDesignModalProps {
    show: boolean,
    onHide(): void,
}

function InteriorDesignModal({ show, onHide }: IInteriorDesignModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="interor-design-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Interior Design
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                Interior Folien eignen sich hervorragend um flache oder gekrümmte Oberflächen wie Wände, Möbel, Türen oder Küchen
                einen neuen Look zu verpassen. Die Flächen werden aufgefrischt oder veredelt. Die Folien sind in folgenden Ausführungen erhältlich:
                Holz, Stoff, Leder, Marmor, Metallic, Glitzer, Naturstein oder Volltonfarben.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default InteriorDesignModal;