import React from 'react';
import banner from '../assets/images/banner.jpg';
import { Img } from 'react-image';
import loading from '../assets/images/gifs/loading.gif';

function Banner() {
    return (
        <section className="section-banner">
            <div className="banner-container">
                <Img className="banner" src={banner} alt="Banner" loader={<img src={loading} alt="Loading" />} />
                <p className="banner-heading">Wir beschriften Ihre<br /> eigene visuelle Botschaft. <br />Lassen sie es für sich<br />einzigartig und für andere<br />identifizierbar sein.</p>
            </div>
        </section>

    );
}

export default Banner;