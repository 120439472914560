import React from 'react';
import { Modal } from 'react-bootstrap';

interface IImplementationModalProps {
    show: boolean,
    onHide(): void,
}

function ImplementationModal({ show, onHide }: IImplementationModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="implementation-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Umsetzung
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Unsere Mitarbeiter sind gerüstet für jegliche Art von Montagen.
                    Ob am Fahrzeug oder auch in der Höhe. Unser Fachpersonal erledigt die Montage stets termingetreu mit Effizienz und Professionalität.
                    Sodass Ihre Reklame wirksam und sichtbar platziert wird.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default ImplementationModal;