import React from 'react';
import { Modal } from 'react-bootstrap';

interface ISchilderModalProps {
    show: boolean,
    onHide(): void,
}

function SchilderModal({ show, onHide }: ISchilderModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="schilder-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Schilder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Ein Schild sagt mehr als tausend Worte.
                    Unsere Schilder schaffen Orientierung und machen auf das aufmerksam,
                    was Ihnen wichtig ist!
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default SchilderModal;