export default class ReCaptcha {
    private id: string;
    private script = document.createElement('script');
    private widget: any;
    private theme: string
    private setVerified: () => void;
    private siteKey = '6Lc1s_0bAAAAALE3ftk__bKBRlkyQxIbq7T5ZEbd'
    // not in use, because currently there is no backend to verify the token
    // private secret = '6Lc1s_0bAAAAAKJ0quJGvXYlG4BreJOL7YEbUHzf';

    constructor(id:string, theme: string, setVerified: () => void) {
        this.id = id;
        this.theme = theme;
        this.setVerified = setVerified;
    }

    load() {
        this.script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
        this.script.async = true;
        this.script.defer = true;
        document.body.appendChild(this.script);
        this.render();
    }

    private render() {
        setTimeout(() => {
            if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
                this.render();
            } else {
                this.widget = grecaptcha.render(this.id, {
                    'sitekey': this.siteKey,
                    'theme': this.theme,
                    'callback': () => this.onVerified(),
                });
            }
        }, 100);
    }

    cleanup() {
        document.body.appendChild(this.script);
    }

    async onVerified() {
        const token = grecaptcha.getResponse(this.widget);
        // not in use, because currently there is no backend to verify the token
        // const success = await this.verifyToken(token);
        this.setVerified();
    }

    // not in use, because currently there is no backend to verify the token
    // async verifyToken(token: string) {
    //     const options = {
    //         secret: this.secret,
    //         response: token,
    //     }
    //     try {
    //         const response = await fetch('/api/recaptcha/siteverify', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(options)
    //         });
    //         const data = await response.json();
    //         const { challenge_ts, hostname, success } = data;
    //         return success;
    //     } catch (error) {
    //         return false;
    //     }
    // }

    reset() {
        grecaptcha.reset(this.widget);
    }
}