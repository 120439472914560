import React from 'react';
import implementationExample from '../assets/images/implementation_example.jpg';
import { Img } from 'react-image';
import loading from '../assets/images/gifs/loading.gif';

function ImplementationExample() {
    return (
        <section className="section-implementation-example">
            <div className="implementation-example-container">
                <Img src={implementationExample} alt="Umsetzungsbeispiel" loader={<img src={loading} alt="Loading" />} />
            </div>
            <p>Mit Beschriftungen von Castro Visual wird Ihre Botschaft sichtbar.<br/>
                Wir entwerfen, produzieren und beschriften Ihren Ladenlokal, Ihr Büro oder Ihre Werkstatt.
            </p>
        </section>
    );
}

export default ImplementationExample;