import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import castrovisualCol from '../assets/images/castrovisual_col.png';
import castrovisualRow from '../assets/images/castrovisual_row.png';
import loading from '../assets/images/gifs/loading.gif';
import MenuModal from '../modals/MenuModal';
import { Img } from 'react-image';

function Header() {
    const [showMenuModal, setShowMenuModal] = useState(false);

    return (
        <section className="section-header">
            <div className="header-container">
                <a className="logo-col" href="#"><Img src={castrovisualCol} alt="Castro Visual Werbetechnik &amp; Signaletik" loader={<img src={loading} alt="Loading" />} /></a>
                <a className="logo-row" href="#"><Img src={castrovisualRow} alt="Castro Visual Werbetechnik &amp; Signaletik" loader={<img src={loading} alt="Loading" />} /></a>
                <ul className="menu-ul">
                    <li><a href="#unternehmen">Unternehmen</a></li>
                    <li><a href="#unser-service">Unser Service</a></li>
                    <li><a href="#referenz">Referenz</a></li>
                    <li><a href="#sponsoring">Sponsoring</a></li>
                    <li><a href="#kontakt">Kontakt</a></li>
                    <li><a href="#standort">Standort</a></li>
                </ul>
                <div className="menu-btn">
                    <FaBars onClick={() => setShowMenuModal(true)} />
                </div>
            </div>
            <MenuModal show={showMenuModal} onHide={() => setShowMenuModal(false)} />
        </section >
    );
}

export default Header;