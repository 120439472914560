import React from 'react';
import { Modal } from 'react-bootstrap';

interface IProductionModalProps {
    show: boolean,
    onHide(): void,
}

function ProductionModal({ show, onHide }: IProductionModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="production-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Produktion
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Der grafische Entwurf darf in die Produktion.
                    Die Herstellung findet bei uns im eigenen Betrieb statt.
                    Unser Atelier ist mit modernsten Maschinen ausgestattet, daher garantieren wir für hohe Qualität der Drucke.
                    Unsere Sorgfalt und Passion sind unsere Stärken in der Produktion. Damit Ihre Reklame das Publikum überzeugt.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default ProductionModal;