import React, { useState } from 'react';
import { Img } from 'react-image';
import interiorDesign from '../assets/images/services/interior_design.jpg';
import aussenwerbung from '../assets/images/services/aussenwerbung.jpg';
import digitaldruck from '../assets/images/services/digitaldruck.jpg';
import lichtwerbung from '../assets/images/services/lichtwerbung.jpg';
import schilder from '../assets/images/services/schilder.jpg';
import sichtUndSonnenschutz from '../assets/images/services/sicht_und_sonnenschutz.jpg';
import bannerUndFahnen from '../assets/images/services/banner_und_fahnen.jpg';
import standwerbeanlagen from '../assets/images/services/standwerbeanlagen.jpg';
import fahrzeugbeschriftung from '../assets/images/services/fahrzeugbeschriftung.jpg';
import InteriorDesignModal from '../modals/InteriorDesignModal';
import AussenwerbungModal from '../modals/AussenwerbungModal';
import DigitaldruckModal from '../modals/DigitaldruckModal';
import LichtwerbungModal from '../modals/LichtwerbungModal';
import SchilderModal from '../modals/SchilderModal';
import SichtUndSonnenschutzModal from '../modals/SichtUndSonnenschutzModal';
import BannerUndFahnen from '../modals/BannerUndFahnenModal';
import StandwerbeanlagenModal from '../modals/StandwerbeanlagenModal';
import FahrzeugbeschriftungModal from '../modals/FahrzeugbeschriftungModal';
import loading from '../assets/images/gifs/loading.gif';

interface IService {
    img: string,
    label: string,
    onClick(): void,
}

function Services() {
    const [showInteriorDesignModal, setShowInteriorDesignModal] = useState(false);
    const [showAussenwerbungModal, setShowAussenwerbungModal] = useState(false);
    const [showDigitaldruckModal, setShowDigitaldruckModal] = useState(false);
    const [showLichtwerbungModal, setShowLichtwerbungModal] = useState(false);
    const [showSchilderModal, setShowSchilderModal] = useState(false);
    const [showSichtUndSonnenschutzModal, setShowSichtUndSonnenschutzModal] = useState(false);
    const [showBannerUndFahnenModal, setShowBannerUndFahnenModal] = useState(false);
    const [showStandwerbeanlagenModal, setShowStandwerbeanlagenModal] = useState(false);
    const [showFahrzeugbeschriftungModal, setShowFahrzeugbeschriftungModal] = useState(false);
    const [featured, setFeatured] = useState<Array<IService>>([
        {
            img: interiorDesign,
            label: 'Interior Design',
            onClick: () => setShowInteriorDesignModal(true),
        },
    ]);
    const [services, setServices] = useState<Array<IService>>([
        {
            img: aussenwerbung,
            label: 'Aussenwerbung',
            onClick: () => setShowAussenwerbungModal(true),
        },
        {
            img: digitaldruck,
            label: 'Digitaldruck',
            onClick: () => setShowDigitaldruckModal(true),
        },
        {
            img: lichtwerbung,
            label: 'Lichtwerbung',
            onClick: () => setShowLichtwerbungModal(true),
        },
        {
            img: schilder,
            label: 'Schilder',
            onClick: () => setShowSchilderModal(true),
        },
        {
            img: sichtUndSonnenschutz,
            label: 'Sicht- und Sonnenschutz',
            onClick: () => setShowSichtUndSonnenschutzModal(true),
        },
        {
            img: bannerUndFahnen,
            label: 'Banner und Fahnen',
            onClick: () => setShowBannerUndFahnenModal(true),
        },
        {
            img: standwerbeanlagen,
            label: 'Standwerbeanlagen',
            onClick: () => setShowStandwerbeanlagenModal(true),
        },
        {
            img: fahrzeugbeschriftung,
            label: 'Fahrzeugbeschriftung',
            onClick: () => setShowFahrzeugbeschriftungModal(true),
        },
    ]);

    return (
        <section id="unser-service" className="section-services">
            <article className="intro-services">
                <h2>Unser Service</h2>
                <p>NEUHEIT im 2024</p>
            </article>
            <div className="service-container featured">
                {
                    featured.map((service, index) => {
                        return (
                            <div className="service featured" onClick={service.onClick} key={`featured-service-${index}`}>
                                <Img src={service.img} alt={service.label} loader={<img src={loading} alt="Loading" />} />
                                <p>{service.label}</p>
                            </div>
                        );
                    })
                }
            </div>
            <div className="service-container">
                {
                    services.map((service, index) => {
                        return (
                            <div className="service" onClick={service.onClick} key={`service-${index}`}>
                                <Img src={service.img} alt={service.label} loader={<img src={loading} alt="Loading" />} />
                                <p>{service.label}</p>
                            </div>
                        );
                    })
                }
            </div>
            <InteriorDesignModal show={showInteriorDesignModal} onHide={() => setShowInteriorDesignModal(false)} />
            <AussenwerbungModal show={showAussenwerbungModal} onHide={() => setShowAussenwerbungModal(false)} />
            <DigitaldruckModal show={showDigitaldruckModal} onHide={() => setShowDigitaldruckModal(false)} />
            <LichtwerbungModal show={showLichtwerbungModal} onHide={() => setShowLichtwerbungModal(false)} />
            <SchilderModal show={showSchilderModal} onHide={() => setShowSchilderModal(false)} />
            <SichtUndSonnenschutzModal show={showSichtUndSonnenschutzModal} onHide={() => setShowSichtUndSonnenschutzModal(false)} />
            <BannerUndFahnen show={showBannerUndFahnenModal} onHide={() => setShowBannerUndFahnenModal(false)} />
            <StandwerbeanlagenModal show={showStandwerbeanlagenModal} onHide={() => setShowStandwerbeanlagenModal(false)} />
            <FahrzeugbeschriftungModal show={showFahrzeugbeschriftungModal} onHide={() => setShowFahrzeugbeschriftungModal(false)} />
        </section>
    );
}

export default Services;