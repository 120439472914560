import React from 'react';
import { Modal } from 'react-bootstrap';

interface IPlaningModalProps {
    show: boolean,
    onHide(): void,
}

function PlaningModal({ show, onHide }: IPlaningModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="planing-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Planung
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Bei uns erfahren Sie eine fachliche Beratung im Bereich Werbetechnik.
                    Durch Ihre Ideen und Wünschen lassen wir uns inspirieren und gestalten Ihre Wunschreklame.
                    Die Bestandsaufnahme vor Ort rundet unsere Planung ab.
                </p>
                <p>
                    Werbeanlagen sind in den meisten Fällen Bewilligungspflichtig. Wir übernehmen
                    die Zusammenstellung des Baugesuchs und reichen die Dokumente
                    bei der zuständigen Behörde ein.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default PlaningModal;