import React from 'react';
import c from '../assets/images/c.png';
import agb from '../assets/files/castro_visual_agb.pdf';
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa';

function Footer() {
    return (
        <footer id="footer">
            <div className="social-media-container">
                <a href="https://www.facebook.com/castrovisual.ch/" target="_blank" rel="noreferrer"><FaFacebook /></a>
                <a href="https://www.instagram.com/castro_visual/?hl=de" target="_blank" rel="noreferrer"><FaInstagramSquare /></a>
            </div>
            <ul className="links">
                <li><a href="#">www.castrovisual.ch</a></li>
                <li><a href={agb} download="Castro_Visual_AGB.pdf">AGB - Castro Visual</a></li>
            </ul>
            <div className="c-container">
                <a href="#"><img src={c} alt="Castro Visual Werbetechnik &amp; Signaletik" /></a>
            </div>
        </footer>
    );
}

export default Footer;